
import {editor} from './editor';

export default editor.extend({
  mixins:[editor],
  async mounted() {
    const _result:any = await this.validateValueType(this.valueTypes.int, false);
    if (!_result?.isInvalid) return;
    this.valueTypeError = _result;
  },
  computed: {
    productParams() {
      if (!this.isDefined) return [];
      return this.productParamDataSource.items.filter((p:any) => p.productClassParamId === this.productClassParamId);
    },
  },
  methods: {
    isInclude(value) {
      if (!(this.productParams?.length > 0)) return false;
      return this.productParams.some((p:any) => p.value == value);
    },

    async addOrDelete(item) {
      if (this.isInclude(item.value)) {
        const param = this.productParams.find((p:any) => p.value == item.value);
        await this.deleteProductParam(param.id);
      } else {
        await this.addProductParam(item.id,item.value,item.caption, this.valueTypes.int, item.caption,"");
      }
    },
  }
});
