import Vue from 'vue';

const valueTypeKeys = {
  1: "string",
  2: "int",
  3: "decimal",
  4: "boolean",
  5: "object"
}

export const editor = Vue.extend({
  props: {
    editor: Object,
    productClassParamId: Number,
    dataSource: Object,
    param: Object,
    productParamDataSource: Object,
  },
  data: () => ({
    valueTypeError: null as any,
    valueTypes: {
      string: 1,
      int: 2,
      decimal: 3,
      boolean: 4,
      object: 5
    }
  }),
  computed: {
    productId() {
      return this.dataSource?.model?.id
    },
    isDefined() {
      const productParams = this.productParamDataSource.items;
      if (!(productParams?.length > 0)) return false;
      return productParams.some((p: any) => p.productClassParamId === this.productClassParamId)
    },
    productParam() {
      if (!(this as any).isDefined) return null;
      return this.productParamDataSource.items.find((p: any) => p.productClassParamId === this.productClassParamId);
    },
  },

  methods: {
    parceJson: (json) => {
      try {
        return JSON.parse(json);
      } catch (e) {
        return false;
      }
    },
    async validateValueType(valueTypeId, isSingle) {
      if (!valueTypeKeys[valueTypeId]) throw new Error(`${valueTypeId} не явлюется валидным типом данных`);
      const _productParams = (this as any).productParamDataSource.items;
      if (!(_productParams?.length > 0)) return;
      const _filteredParams = _productParams
        .filter((p: any) => p.productClassParamId === this.productClassParamId)
      if (!(_filteredParams?.length > 0)) return;
      if (isSingle && _filteredParams.length > 1) {
        return {
          isInvalid: true,
          message: `Данный редактор может обрабатывать только 1 параметр. Найдено ${_filteredParams.length} параметров`,
          itemsToDelete: _filteredParams
        };
      }

      const _invalidParams = _filteredParams.filter((p: any) => +p.valueTypeId !== +valueTypeId)
      if (!(_invalidParams?.length > 0)) return;

      return {
        isInvalid: true,
        message: `Ошибка при обработке типа данных `,
        itemsToDelete: _invalidParams
      }
    },
    async clearProductParam(itemsToDelete) {
      if (!(itemsToDelete?.length > 0)) return;
      await Promise.all(itemsToDelete.map((p: any) => (this as any).deleteProductParam(p.id)));
      this.valueTypeError = null;
    },

    async updateProductParam(productClassParamItemId, newValue: string, valueCaption: any, productParamId: number, caption: string, description: string) {
      const productParams = this.productParamDataSource.items;
      if (!(productParams?.length > 0)) return;
      const index = productParams.findIndex((p: any) => p.id === productParamId);
      const productParam = productParams.find((p: any) => p.id === productParamId);
      if (index === -1 || !productParam) throw new Error("элемент не найден");

      productParam.productClassParamItemId = productClassParamItemId;
      productParam.value = newValue.toString();
      productParam.valueCaption = valueCaption;
      productParam.caption = caption ?? "параметр продукта";
      productParam.description = description;
      productParam.measureCaption = (this as any).editor.measureCaption;


      try {
        await this.productParamDataSource.update(productParam)
      } finally {
        (this as any).$message("Успешно обновлён");
      }
    },
    async deleteProductParam(productParamId: number) {
      const productParams = this.productParamDataSource.items;
      if (!(productParams?.length > 0)) return;
      const index = productParams.findIndex((p: any) => p.id === productParamId);
      if (index === -1) throw new Error("элемент не найден");
      try {
        await this.productParamDataSource.remove(productParamId)
      } finally {
        (this as any).$message("Успешно удалён");
      }


    },
    async addProductParam(productClassParamItemId, value, valueCaption, valueTypeId, caption = "параметр продукта", description = "") {
      if (!valueTypeKeys[valueTypeId]) throw new Error("Передан не правельный тип значения");
      const { productClassParamId, productId } = this as any;
      if (!productClassParamId && productClassParamId !== 0) throw new Error("productClassParamId не может быть null");
      if (!productId && productId !== 0) throw new Error("productId не может быть null");
      try {
        await this.productParamDataSource.add({
          productClassParamItemId,
          productId,
          valueCaption,
          productClassParamId,
          valueTypeId: valueTypeId,
          value: value.toString(),
          caption: caption ?? "параметр продукта",
          description
        })
      } finally {
        (this as any).$message("Успешно добавлен");
      }



    }
  }
});

