
import {editor} from './editor';

export default editor.extend({
  mixins:[editor],
  computed: {
    editorValue() {
      return this?.productParam?.value === 'true';
    },
  },
  async mounted() {
    const _result = await this.validateValueType(this.valueTypes.boolean, true);
    if (!_result?.isInvalid) return;
    this.valueTypeError = _result;
  },
  methods: {
    async changeValue(val) {
      await this.updateProductParam(null,val ? 'true' : 'false',this.param.caption, this.productParam?.id, this.editor.label,"");
    },
  },
});
