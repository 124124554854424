
import VSwatches from 'vue-swatches';
import {editor} from './editor';
import 'vue-swatches/dist/vue-swatches.css';

export default editor.extend({
  mixins:[editor],
  components: { VSwatches },
  async mounted() {
    const _result = await this.validateValueType(this.valueTypes.string, (this as any).mode === 'single');
    if (!_result?.isInvalid) return;
    this.valueTypeError = _result;
  },
  data() {
    return {
      valueTypeError: null,
    };
  },
  computed: {
    mode() {
      return this.editor?.mode ?? 'sinle';
    },
    productParam() {
      if (!this.isDefined) return null;
      return this.productParamDataSource.items.find((p:any) => p.productClassParamId === this.productClassParamId);
    },
    productParams() {
      if (!this.isDefined) return [];
      return this.productParamDataSource.items.filter((p:any) => p.productClassParamId === this.productClassParamId);
    },
    editorValue() {
      return this.productParam?.value ?? null;
    },
  },
  methods: {
    isInclude(value) {

      if (!((this as any).productParams?.length > 0)) return false;
      return (this as any).productParams.some((p:any) => p.value == value);
    },
    async addOrDelete(item) {
      if ((this as any).isInclude(item.value)) {
        const param = (this as any).productParams.find((p:any) => p.value == item.value);
        await this.deleteProductParam(param.id);
      } else {
        await this.addProductParam(item.id,item.value,item.caption, this.valueTypes.string, item.caption,"");
      }
    },
    isSelected(label) {
      return label === (this as any).editorValue;
    },
    async selectColor(item) {
      if (!item) throw new Error('Элемент не найден');
      // eslint-disable-next-line no-unused-expressions
      this.productParam?.id
        ? await this.updateProductParam(item.id,item.value,item.caption, this.productParam?.id, item.caption,"")
        : await this.addProductParam(item.id,item.value,item.caption, this.valueTypes.string, item.caption,"");
    },
  },
});
