
import {editor} from './editor';

export default editor.extend({
  mixins:[editor],
  async mounted() {
    const _result = await this.validateValueType(this.valueTypes.int, true);
    if (!_result?.isInvalid) return;
    this.valueTypeError = _result;
  },
  computed: {
    mode() {
      return this.editor?.mode ?? 'radio';
    },
    editorValue() {
      const value = this?.productParam?.value ?? null;
      const { int, decimal } = this.valueTypes;
      return [int, decimal].includes(+this?.productParam.valueTypeId) ? +value : value;
    },
  },
  methods: {
    async change(item) {
      if (!this.productParam?.id) {
        await this.addProductParam(item.id,item.value, item.caption, this.valueTypes.int,"","");
        return;
      }
      await this.updateProductParam(item.id,item.value, item.caption, this.productParam?.id, item.caption,"");
    },
  },
});
