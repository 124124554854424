
import {editor} from './editor';

export default editor.extend({
  mixins:[editor],
  async mounted() {
    const _result = await this.validateValueType(this.valueTypes.string, true);
    if (!_result?.isInvalid) return;
    this.valueTypeError = _result;
  },
  computed: {
    productParam() {
      if (!this.isDefined) return null;
      return this.productParamDataSource.items.find((p:any) => p.productClassParamId === this.productClassParamId);
    },
  },
});
